import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Environment } from '@leanpay/utils'

import App from 'app/app'

import {
    currentEnvironment,
    currentMarket,
    gtmId,
    isMarketSlo,
    isProdEnvironment,
} from 'library/constants'

import './i18n'

if (isMarketSlo && currentEnvironment !== Environment.LOCAL) {
    Sentry.init({
        dsn: 'https://56d872c3bbdc78970e207b4727b720b2@o4508023659560960.ingest.de.sentry.io/4508023664017488',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: currentEnvironment.toLowerCase(),
        ignoreErrors: [
            'Non-Error promise rejection captured with value: VENDOR_API_TOKEN_UNKNOWN',
            'Non-Error promise rejection captured with value: 502',
        ],
    })
    Sentry.setTag('market', currentMarket.toLowerCase())
}

if (isProdEnvironment) {
    const tagManagerArgs = {
        gtmId,
    }

    TagManager.initialize(tagManagerArgs)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)
